import React from 'react';
import PropTypes from 'prop-types';
import { surveyPropTypes } from 'helpers/proptypes';

import SurveyAdminRow from './SurveyAdminRow';

export default class SurveyAdminList extends React.Component {
  static propTypes = {
    surveys: PropTypes.arrayOf(surveyPropTypes),
    onCopyClick: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className="table-responsive" style={{ overflow: 'visible' }}>
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Event</th>
              <th style={{ minWidth: '160px' }}>Author</th>
              <th>Status</th>
              <th style={{ width: '115px' }}>Last Modified</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.props.surveys.map((survey) => (
              <SurveyAdminRow
                key={survey.id}
                surveyId={survey.id}
                authorId={survey.author}
                onCopyClick={this.props.onCopyClick}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
