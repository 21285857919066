import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { copySurveyThunk, createSurveyThunk, surveyListThunk } from 'store/actions/surveys';
import { requestCurrentUser } from 'store/actions/accounts/user';
import { EventDictionary } from 'helpers/dictionaries';
import { surveyPropTypes, userPropTypes } from 'helpers/proptypes';
import { Loading, PageHeader } from 'components';
import { getListFromIds } from 'store/selectors';
import SurveyAdminList from './SurveyAdminList';
import SurveyCreateModal from './SurveyCreateModal';

function sortByDraftAndClose(a, b) {
  if (
    a.publication_status === EventDictionary.PUB_STATUS.DRAFT &&
    b.publication_status !== EventDictionary.PUB_STATUS.DRAFT
  ) {
    return -1;
  }
  if (
    b.publication_status === EventDictionary.PUB_STATUS.DRAFT &&
    a.publication_status !== EventDictionary.PUB_STATUS.DRAFT
  ) {
    return 1;
  }
  return new Date(b.end) - new Date(a.end);
}

export class SurveyAdminListPage extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    hasErrored: PropTypes.bool.isRequired,
    user: userPropTypes,
    copySurveyThunk: PropTypes.func.isRequired,
    createSurveyThunk: PropTypes.func.isRequired,
    surveyListThunk: PropTypes.func.isRequired,
    requestCurrentUser: PropTypes.func.isRequired,
    surveys: PropTypes.arrayOf(surveyPropTypes),
  };

  state = {
    modalOpen: false,
  };

  handleModalOpen = (e) => {
    e.preventDefault();
    this.setState({ modalOpen: true });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  handleCreateSurveyFormSubmit = (values) => {
    values.event_id = values.event;
    delete values.event;
    this.props.createSurveyThunk({ author: this.props.user.pk, ...values });
    this.setState({ modalOpen: false });
  };

  handleCopyClick = (surveyId) => this.props.copySurveyThunk(surveyId);

  componentDidMount() {
    this.props.surveyListThunk();
    this.props.requestCurrentUser();
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.isLoading === true && nextProps.isLoading === true) {
      return false;
    }
    return true;
  }

  render() {
    if (this.props.isLoading) {
      return <Loading />;
    }
    if (this.props.hasErrored) {
      // TODO(ryan)
    }
    const templateSurveys = this.props.surveys.filter((survey) => !!survey.template);
    const builtSurveys = this.props.surveys
      .filter((survey) => !survey.template)
      .sort(sortByDraftAndClose);
    return (
      <div>
        <div className="col-xs-12 white-soft-box2 white-soft-box2--no-animate">
          <PageHeader.Basic
            title="Survey Admin"
            actions={
              <button className="btn btn-default pull-right" onClick={this.handleModalOpen}>
                <i className="fa fa-plus icon-space-r" />
                Create Survey
              </button>
            }
          />
          <div className="mt1" />
          <h3>Survey Templates</h3>
          <SurveyAdminList surveys={templateSurveys} onCopyClick={this.handleCopyClick} />
          <h3>Built Surveys</h3>
          <SurveyAdminList surveys={builtSurveys} onCopyClick={this.handleCopyClick} />
        </div>
        <SurveyCreateModal
          modalOpen={this.state.modalOpen}
          onModalClose={this.handleModalClose}
          onFormSubmit={this.handleCreateSurveyFormSubmit}
        />
        <ReactTooltip effect="solid" type="dark" delayShow={500} globalEventOff="click" />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.surveys.keys.isLoading,
    hasErrored: state.surveys.keys.hasErrored,
    user: state.accountsUser[state.accountsUser.current],
    surveys: getListFromIds(state.surveys),
  };
};

export default connect(
  mapStateToProps,
  {
    copySurveyThunk,
    createSurveyThunk,
    surveyListThunk,
    requestCurrentUser,
  }
)(SurveyAdminListPage);
