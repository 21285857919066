import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-validify';

import { Events } from 'api';
import { EventSelection } from 'components/shared';
import { FormInput } from 'components';

const dropdownStyle = {
  zIndex: 1100,
};

class SurveyCreateForm extends React.Component {
  static propTypes = {
    onFormSubmit: PropTypes.func.isRequired,
    submitButtonText: PropTypes.string,
  };

  state = {
    events: [],
    selectedEvent: '',
  };

  handleSelectedEventChange = (selectedEvent) => this.setState({ selectedEvent });

  componentDidMount() {
    Events.list().then((events) => {
      this.setState({ events });
    });
  }

  render() {
    return (
      <Form
        rules={{
          name: 'string|required',
        }}
      >
        <FormInput
          id="name"
          name="name"
          type="text"
          className="form-control"
          label={{ htmlFor: 'name', text: 'Survey Name' }}
        />
        <EventSelection
          label="Which event will this survey be associated with?"
          event={this.state.selectedEvent}
          events={this.state.events}
          onChange={this.handleSelectedEventChange}
          dropdownStyle={dropdownStyle}
        />
        <div className="form-group mt2">
          <button
            type="button"
            submit
            className="btn btn-primary"
            onClick={(values) =>
              this.props.onFormSubmit({ ...values, event: this.state.selectedEvent })
            }
          >
            {this.props.submitButtonText || 'Submit'}
          </button>
        </div>
      </Form>
    );
  }
}

export default SurveyCreateForm;
