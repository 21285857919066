import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { RelativeDate } from 'components';
import { requestUser } from 'store/actions/accounts/user';
import { surveyPropTypes, userPropTypes } from 'helpers/proptypes';

import SurveyAdminDropdownButton from 'components/shared/SurveyAdminDropdownButton';
import SurveyStatus from '../SurveyStatus';

class SurveyAdminRow extends React.Component {
  static propTypes = {
    survey: surveyPropTypes.isRequired,
    author: userPropTypes,
    requestUser: PropTypes.func.isRequired,
    onCopyClick: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (!this.props.survey.author.name) {
      this.props.requestUser(this.props.survey.author);
    }
  }

  onCopyClick = (e) => {
    e.preventDefault();
    this.props.onCopyClick(this.props.survey.id);
  };

  render() {
    const { survey, author } = this.props;
    const date = moment(survey.modified ? survey.modified : survey.created);

    return (
      <tr>
        <td>
          <Link to={window.URLS['surveys:survey_admin_detail'](survey.id)}>{survey.name}</Link>
        </td>
        <td>
          {survey.event ? (
            <React.Fragment>
              {survey.event.title}
              <Link to={window.URLS['pd:event_detail'](survey.event.id)} title="Go to event detail">
                <span className="fa fa-angle-double-right icon-space-l" />
              </Link>
            </React.Fragment>
          ) : (
            '-'
          )}
        </td>
        <td>
          {author && author.name ? (
            <React.Fragment>
              {author.name}
              <Link
                to={window.URLS['accounts:public_profile_detail'](author.profile.slug)}
                title="Go to profile"
              >
                <span className="fa fa-angle-double-right icon-space-l" />
              </Link>
            </React.Fragment>
          ) : (
            <span className="fa fa-circle-o-notch fa-spin" />
          )}
        </td>
        <td>
          <SurveyStatus survey={survey} statusOnly />
        </td>
        <td>
          <RelativeDate date={date} />
        </td>
        <td>
          <SurveyAdminDropdownButton id={survey.id} onCopyClick={this.onCopyClick} />
        </td>
      </tr>
    );
  }
}

const mapStateToProps = ({ accountsUser, surveys }, { surveyId, authorId }) => {
  const survey = surveys.keys[surveyId];
  const author = accountsUser[authorId];
  return {
    survey,
    author,
  };
};

export default connect(
  mapStateToProps,
  { requestUser }
)(SurveyAdminRow);
