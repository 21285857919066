import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';

import SurveyCreateForm from './SurveyCreateForm';

const SurveyCreateModal = ({ modalOpen, onModalClose, onFormSubmit }) => {
  return (
    <Modal show={modalOpen} onHide={onModalClose} modalPrefix="bootstrap-modal modal" large>
      <Modal.Header closeButton>
        <Modal.Title>Create Survey</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SurveyCreateForm onFormSubmit={onFormSubmit} />
      </Modal.Body>
    </Modal>
  );
};

SurveyCreateModal.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
};

export default SurveyCreateModal;
